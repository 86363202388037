// Set utils function parseTime to filter
export { parseTime } from '@/utils'; // Filter for article status

export var articleStatusFilter = function articleStatusFilter(status) {
  var statusMap = {
    published: 'success',
    draft: 'info',
    deleted: 'danger'
  };
  return statusMap[status];
}; // Filter to uppercase the first character

export var uppercaseFirstChar = function uppercaseFirstChar(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};