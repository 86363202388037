import axios from 'axios';
import { Message } from 'element-ui';
import { UserModule } from '@/store/modules/user';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 600000 // withCredentials: true // send cookies when cross-domain requests

}); // Request interceptors

service.interceptors.request.use(function (config) {
  // Add X-Access-Token header to every request, you can add other custom headers here
  if (UserModule.token) {
    config.headers['X-Access-Token'] = UserModule.token;
  }

  return config;
}, function (error) {
  Promise.reject(error);
}); // Response interceptors

service.interceptors.response.use(function (response) {
  // Some example codes here:
  // code == 20000: success
  // code == 50001: invalid access token
  // code == 50002: already login in other place
  // code == 50003: access token expired
  // code == 50004: invalid user (user not exist)
  // code == 50005: username or password is incorrect
  // You can change this part for your own usage.
  var res = response.data;

  if (res.code !== 200) {
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });

    if (res.code === 50003) {
      UserModule.ResetToken();
      location.reload(); // To prevent bugs from vue-router

      return response.data;
    }

    return response.data;
  } else {
    return response.data;
  }
}, function (error) {
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;