import Layout from '@/layout/index.vue';
var agentsRouter = {
  path: '/agents',
  component: Layout,
  name: 'agents',
  meta: {
    title: 'agents',
    icon: 'list',
    alwaysShow: true,
    roles: ['admin']
  },
  children: [{
    path: 'list',
    component: function component() {
      return import(
      /* webpackChunkName: "agentList" */
      '@/views/agents/List.vue');
    },
    name: 'agentList',
    meta: {
      title: 'agentList',
      roles: ['admin'],
      noCache: true
    }
  }, {
    path: 'register',
    component: function component() {
      return import(
      /* webpackChunkName: "agentRegister" */
      '@/views/agents/Register.vue');
    },
    name: 'agentRegister',
    meta: {
      title: 'agentRegister',
      roles: ['admin'],
      noCache: true
    }
  }]
};
export default agentsRouter;