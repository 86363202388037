import Layout from '@/layout/index.vue';
var gameSettingRouter = {
  path: '/gameSetting',
  component: Layout,
  meta: {
    title: 'gameSetting',
    icon: 'edit',
    roles: ['admin', '500', '300', '400'],
    alwaysShow: true // will always show the root menu

  },
  children: [{
    path: 'systemConfig',
    component: function component() {
      return import(
      /* webpackChunkName: "notice" */
      '@/views/gameSetting/SystemConfig.vue');
    },
    name: 'systemConfig',
    meta: {
      title: 'systemConfig',
      roles: ['admin', '500', '300', '400']
    }
  }, {
    path: 'notice',
    component: function component() {
      return import(
      /* webpackChunkName: "notice" */
      '@/views/gameSetting/Notice.vue');
    },
    name: 'notice',
    meta: {
      title: 'notice',
      roles: ['admin', '500', '300', '400']
    }
  }, {
    path: 'mail',
    component: function component() {
      return import(
      /* webpackChunkName: "mail" */
      '@/views/gameSetting/Mail.vue');
    },
    name: 'mail',
    meta: {
      title: 'mail',
      roles: ['admin', '500', '300', '400']
    }
  }, {
    path: 'version',
    component: function component() {
      return import(
      /* webpackChunkName: "version" */
      '@/views/gameSetting/Version.vue');
    },
    name: 'version',
    meta: {
      title: 'version',
      roles: ['admin']
    }
  }, {
    path: 'blackList',
    component: function component() {
      return import(
      /* webpackChunkName: "notice" */
      '@/views/gameSetting/blackList.vue');
    },
    name: 'blackList',
    meta: {
      title: 'blackList',
      roles: ['admin']
    }
  }, {
    path: 'packageStock',
    component: function component() {
      return import(
      /* webpackChunkName: "packageStock" */
      '@/views/gameSetting/PackageStock.vue');
    },
    name: 'packageStock',
    meta: {
      title: 'packageStock',
      roles: ['admin']
    }
  }, {
    path: 'banner',
    component: function component() {
      return import(
      /* webpackChunkName: "notice" */
      '@/views/gameSetting/Banner.vue');
    },
    name: 'banner',
    meta: {
      title: 'banner',
      roles: ['admin', '300']
    }
  }]
};
export default gameSettingRouter;