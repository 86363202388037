exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.showRightPanel {\n  overflow: hidden;\n  position: relative;\n  width: calc(100% - 15px);\n}", ""]);

// exports
exports.locals = {
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};