import request from '@/utils/request';
export var getUsers = function getUsers(params) {
  return request({
    url: '/users',
    method: 'get',
    params: params
  });
};
export var getUserInfo = function getUserInfo(data) {
  return request({
    url: '/admin/auth',
    method: 'post',
    data: data
  });
};
export var getUserByName = function getUserByName(username) {
  return request({
    url: "/users/".concat(username),
    method: 'get'
  });
};
export var updateUser = function updateUser(username, data) {
  return request({
    url: "/users/".concat(username),
    method: 'put',
    data: data
  });
};
export var deleteUser = function deleteUser(username) {
  return request({
    url: "/users/".concat(username),
    method: 'delete'
  });
};
export var login = function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data: data
  });
};
export var logout = function logout() {
  return request({
    url: '/admin/logout',
    method: 'post'
  });
};
export var register = function register(data) {
  return request({
    url: '/users/register',
    method: 'post',
    data: data
  });
};