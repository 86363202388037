exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.theme-message,\n.theme-picker-dropdown {\n  z-index: 99999 !important;\n}\n.theme-picker .el-color-picker__trigger {\n  height: 26px !important;\n  width: 26px !important;\n  padding: 2px;\n}\n.theme-picker-dropdown .el-color-dropdown__link-btn {\n  display: none;\n}", ""]);

// exports
exports.locals = {
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};