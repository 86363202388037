export default {
  route: {
    dashboard: 'Dashboard',
    profile: 'Profile',
    user: 'user',
    userQuery: 'Query User',
    statManager: 'Stat Mananger',
    dailyLife: 'Daily Life',
    chargeStat: 'Charge Stat',
    roomStat: 'Table Stat',
    itemStat: 'Item statistics',
    queryItem: 'Item query',
    agents: 'Agents Manager',
    agentRegister: 'Register Agent Link',
    agentList: 'Agent List',
    cardRecord: 'Item records',
    gameSetting: 'Game Setting',
    notice: 'Notification',
    mail: 'Mail',
    operation: 'Operations Management',
    version: 'Version Control',
    goods: 'Products Management',
    queryOrder: 'Query Orders',
    feedback: 'Feedback Center',
    headerImage: 'Review avatar',
    account: 'Account management',
    accountList: 'Account list',
    operateLog: 'Operation log',
    nameRecord: 'Nickname record'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    size: 'Global Size'
  },
  login: {
    title: 'Login Form',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    passwordLen: 'The password can not be less than 6 digits',
    inputUsername: 'Please input your account',
    loginFailed: 'Login failed',
    loginNotFind: 'Account does not exist',
    passwordWrong: 'Password wrong',
    accountNotActive: 'Account is not activated',
    loginOK: 'Login successful'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Open Tags-View',
    showSidebarLogo: 'Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  dashboard: {
    totalUser: 'Total User',
    onlineRooms: 'Online Times',
    purchases: 'Money',
    shopping: 'Order',
    orderId: 'Order ID',
    timestamp: 'Pay Time',
    status: 'Status',
    price: 'Price',
    productId: 'Product ID',
    type: 'Type',
    count: 'Count',
    registerCount: 'Registration number',
    loginCount: 'Login number',
    clubRoom: 'Club Room',
    rooms: 'Ordinary Room',
    amount: 'Amount',
    totalCount: 'Total Count',
    appleOrder: 'Apple Order',
    totalOrder: 'Total Order',
    amounts: 'Total Amount',
    orders: 'Total Order'
  },
  user: {
    userInfo: 'Player information',
    playerId: 'Player ID',
    inputPlayerId: 'Please input the player ID',
    nickname: 'Nickname',
    coin: 'Coin',
    chargeCoin: 'Charge Coin',
    diamond: 'Diamond',
    chargeDiamond: 'Charge Diamond',
    device: 'Device',
    loginToken: 'Login Token',
    session: 'Session',
    sex: 'Sex',
    appid: 'appid',
    channel: 'Channel',
    publisher: 'Publisher',
    createTime: 'Create Time',
    reset: 'Reset',
    inputChargeCount: 'Please input the number of recharges',
    countLimit: 'The number of recharges is between -100,000 and 100,000.',
    userNotExist: 'The player does not exist',
    beforeCount: 'Before operation',
    afterCount: 'After operation',
    mobile: 'telephone',
    from: 'Account Type',
    logoutTime: 'Last Logout Time',
    loginTime: 'Last Login Time',
    reports: 'Report Count',
    forbidTime: 'Time:',
    forbidden: 'Ban:',
    forbidChat: 'Forbidden Chat',
    forbidGame: 'Forbidden Game',
    reportHours: 'Hour',
    unblock: 'Unblock',
    ban: 'Ban',
    forbidChatDeadline: 'Forbidden Chat Deadline',
    forbidGameDeadline: 'Forbidden Game Deadline',
    modifyName: 'Change nickname',
    inputModifyName: 'Please enter a nickname',
    modifyNameCount: 'Modified Nickname times',
    inputModifyNameCount: 'Please enter a Modified Nickname times',
    modifyCount: 'Modify Count',
    fixSmsCode: 'Modify verification code',
    inputSmsCode: 'Please enter the verification code (4 digits)',
    smsCodeError: 'Verification code is 4 digits',
    vip_7_day: '7 days Vip',
    vip_30_day: '30 days Vip',
    vipType: 'Vip type',
    vipLevel: 'Vip level',
    vipEndTime: 'Vip deadline',
    vipCharge: 'Vip recharge',
    vipExp: 'Modify VIP Experience',
    vip_365_day: '365 days vip',
    addVipExp: 'Add VIP Experience',
    role: 'Roles',
    oldName: 'Old nickname',
    newName: 'New nickname',
    vipExpValue: 'VIP Experience'
  },
  agents: {
    updatePassword: 'Update Password',
    inputPassword: 'Please input your password',
    confirm: 'Confirm',
    reset: 'Reset',
    passwordLen: 'Password length cannot be less than 3 digits',
    updatePasswordFailed: 'Password change failed',
    createLink: 'Create Link',
    agentType: 'Distributor type',
    createAgentRegister: 'Generate registration link',
    createLinkAndCopy: 'Please make a registration link, then copy it...',
    selectAgentType: 'Please select a distributor type',
    copy: 'Copy',
    distributor: 'Distributor',
    CreateLinkFailed: 'Registration link failed',
    inputAgentAccount: 'Please input a distributor account',
    search: 'Search',
    id: 'Id',
    account: 'Account',
    password: 'Password',
    registerTime: 'Registration time',
    lastTime: 'Recent login time',
    coinCount: 'Coin count',
    diamondCount: 'Diamond count',
    operate: 'Operating',
    recharge: 'Recharge',
    chargeRecord: 'Buy card record',
    activate: 'Activate',
    unactive: 'Disable',
    modifyPassword: 'Change password',
    chargeType: 'Charge Type',
    selectChargeType: 'Please choose a type',
    count: 'Count',
    chargedId: 'Recharge account ID',
    orderId: 'order Id',
    operateType: 'Operation type',
    chargeBefore: 'Before recharge',
    chargeAfter: 'After recharge',
    chargeTime: 'Recharge time',
    cancel: 'Cancel',
    inputChargeCount: 'Please input the number of recharges',
    inputCountIn100000: 'Please input between -100,000 and 100,000',
    idNotEmpty: 'ID cannot be empty',
    accountNotEmpty: 'Username can not be empty',
    passwordNotEmpty: 'Password can not be blank',
    playerNotExist: 'Player does not exist',
    selectOne: 'Please select a user first',
    admin: 'Super admin',
    service: 'Customer service',
    agent_1: 'Primary distributor',
    agent_2: 'Secondary distributor',
    promoter: 'Promoter',
    player: 'Player',
    chargeCard: 'Charge card record',
    sellCard: 'Sales record',
    outId: 'Sell account',
    inId: 'Buy account',
    buyDiamond: 'Buy diamonds',
    buyCoin: 'Buy gold coins',
    giftDiamond: 'Gift diamond',
    giftCoin: 'Gift gold coin',
    chargeCoin: 'Charge Coin',
    chargeDiamond: 'Charge Diamond',
    playerId: 'Player ID'
  },
  statManager: {
    totalUser: 'Total User',
    onlineRooms: 'Online Rooms',
    newUsers: 'Register Users',
    loginUsers: 'Login Users',
    avrgTime: 'Average Online Time',
    maxTime: 'Max Online Time',
    minTime: 'Min Online Time',
    userOrder: 'Pay Users',
    totalOrder: 'Total Orders',
    appleOrder: 'Apple Orders',
    googleOrder: 'Google Orders',
    diamondOrder: 'Diamond Orders',
    coinOrder: 'Coin Orders',
    totalOrderCnt: 'Orders',
    hanks: 'Total Innings',
    totalPlayers: 'Participate Players',
    clubRoom: 'Club Rooms',
    albCoins: 'Game one',
    albUpGrade: 'Game two',
    albPDK: 'Game three',
    room4Person: '4 Person Room',
    room5Person: '5 Person Room',
    room6Person: '6 Person Room',
    room7Person: '7 Person Room',
    room8Person: '8 Person Room',
    albCoinsUids: 'Game one-Participate Players',
    albUpGradeUids: 'Game two-Participate Players',
    albPDKUids: 'Game three-Participate Players',
    optType1: 'Game consumption',
    optType2: 'Turntable acquisition',
    optType3: 'Friend presentation',
    optType4: 'Apple',
    optType5: 'Google',
    optType6: 'Web',
    optType7: 'Agent Charge',
    optType8: 'Tools Charge',
    optType9: 'New Account obtained',
    optType10: 'Exchange coins',
    optType12: 'Feedback',
    optType13: 'Tools Mail',
    optType100: 'Default',
    date: 'Date',
    type: 'Item type',
    optType: 'Record type',
    startTime: 'Start time',
    endTime: 'End time',
    search: 'Search',
    to: 'To',
    serial3days: '3 days live',
    serial7days: '7 days live',
    optType14: 'Activity',
    optType15: 'System Gift',
    optType16: 'Rank',
    optType17: 'Give Coins',
    optType18: 'Prop conversion',
    optType19: 'Buy Props',
    optType20: 'Ladder Gain',
    optType21: 'Ladder Cost',
    optType22: 'Battle Reward',
    provider: 'Channel',
    originalId: 'Game Order ID',
    state: 'Order Status',
    firstChargeTip: 'Top up',
    firstCharge: 'First Charge',
    orderId: 'Channel Order ID',
    goodsId: 'Product ID',
    amount: 'Amount ($)',
    count: 'Count',
    sar: 'Arab Amount',
    createTime: 'Start Time',
    updateTime: 'Update Time',
    stateInit: 'Initiation',
    stateSuccess: 'Success',
    stateFail: 'Fail',
    stateCannel: 'Cannel',
    unfirstCharge: 'Not First Charge',
    inputGoodsId: 'Please enter the product ID',
    inputOriginalId: 'Please enter the game order ID',
    inputOrderId: 'Please enter the channel order ID',
    optType23: 'vip gift',
    optType24: 'Binding gift',
    optType25: 'Small Game Cost',
    optType26: 'Small Game Award',
    optType27: 'Ladder Winning Award',
    optType28: 'Number of nickname purchases',
    optType29: 'Boxes Cost',
    optType30: 'Boxes Award',
    optType31: 'War Cost',
    optType32: 'War Rewards',
    optType33: 'Gacha Cost',
    optType34: 'Gacha Rewards'
  },
  gameSetting: {
    add: 'Add',
    activity: 'Notice Content',
    time: 'Create Time',
    updateTime: 'Update Time',
    operate: 'Operate',
    edit: 'Edit',
    delete: 'Delete',
    notice: 'Notice',
    notEmpty: 'Announcement cannot be empty',
    save: 'Save',
    addAndEdit: 'Add or edit announcement information',
    title: 'Title',
    inputTitle: 'Please enter a title',
    version: 'Version number',
    inputVersion: 'Please enter the version number',
    type: 'Type',
    selectType: 'Please choose the type',
    text: 'Text',
    image: 'Image',
    imageUrl: 'Image url',
    inputImageUrl: 'Please enter the image url',
    addMail: 'Send email',
    mailContent: 'Email content',
    inputMailContent: 'Please enter the Email content',
    allUser: 'Full player',
    users: 'Some players',
    uids: 'Player ids',
    inputMailUids: 'Please enter player id, separated by comma',
    noGiveItem: 'No coins or diamonds',
    itemType: 'Item type',
    itemCount: 'Number of items',
    inputItemCount: 'Please enter the number of items',
    selectItemType: 'Please select item type',
    jumpUrl: 'Jump Url',
    inputJumpUrl: 'Please enter the Jump Url',
    period: 'Period',
    inputPeriod: 'Please enter the Period',
    times: 'Times',
    once: 'Once',
    day: 'Once a day',
    always: 'Always',
    selectTimesType: 'Please enter the Times',
    place: 'Place',
    selectPlaceType: 'Please enter the Place',
    login: 'Login',
    hall: 'Hall',
    ios_version: 'Apple Version Number',
    inputIOSVersion: 'Please enter the Apple Version Number',
    inputAndroidVersion: 'Please enter the Android Version Number',
    android_version: 'Android Version Number',
    addAndEditVerion: 'Add or Edit the Version',
    fb_type: 'Feedback type',
    fb_report: 'Report',
    fb_feedback: 'Customer Service',
    fb_content: 'Content',
    fb_reportTypes: 'Question type',
    fb_mail: 'Mail',
    fb_images: 'Image',
    fb_reportUid: 'Reported player ID',
    fb_state: 'Status',
    fb_createTime: 'Create time',
    fb_updateTime: 'Processing time',
    fb_init: 'Unprocessed',
    fb_handle: 'Handle',
    fb_reject: 'Refuse',
    fb_reportType1: 'Game problems',
    fb_reportType2: 'Game suggestions',
    fb_reportType3: 'Payment issues',
    fb_reportType4: 'Ban appeal',
    fb_reportType5: 'Other',
    fb_reportType6: 'Malvertising',
    fb_reportType7: 'Abuse',
    fb_reportType8: 'Obscene',
    fb_reportType9: 'Other',
    inputReportUid: 'Please enter the reported player ID',
    fb_showDetail: 'Show details'
  },
  operation: {
    addAndEdit: 'Add or modify items',
    expression: 'Expression',
    gift: 'Swipe gift',
    props: 'Mall props',
    productType: 'Product Types',
    selectProductType: 'Please select a product type',
    productName: 'Product name',
    inpurtProductName: 'Please enter a product name',
    codeName: 'Program name',
    inpurtCodeName: 'Please enter a program name',
    costType: 'Consumption type',
    selectCostType: 'Please select a consumption type',
    productPrice: 'Commodity price',
    inputProductPrice: 'Please enter a product price',
    charmValue: 'Charisma',
    inputCharmValue: 'Please enter a charm value',
    productDesc: 'Product description',
    inputProductDesc: 'Please enter a description',
    timeType: 'Time Type',
    deadline: 'Deadline',
    period: 'Period',
    selectDatetime: 'Select a date time',
    gameProps: 'Game props',
    productStatus: 'Product Status',
    selectProductStatus: 'Please select a product status',
    onShelf: 'Put on shelf',
    offShelf: 'Off shelf',
    tentative: 'Tentative',
    inputRefuseContent: 'Please enter rejected content',
    vipType: 'VIP type',
    selectVipType: 'Please select VIP type',
    all: 'All',
    vip: 'VIP only',
    notvip: 'Non-VIP only',
    periodType: 'Period Type',
    selectPeriodType: 'Please select the period type',
    forever: 'Permanent',
    limit: 'Effective for a limited time',
    days: 'Days',
    selectDays: 'Select days',
    day7: '7 days',
    day30: '30 days'
  },
  roles: {
    account: 'Account manager',
    accountNotEmpty: 'The account manager cannot be empty',
    depart: 'department',
    selectDeparts: 'Please select a department',
    technology: 'Technology',
    product: 'Product',
    operation: 'Operation',
    design: 'Design',
    manager: 'Management',
    selectRoles: 'Please select role permissions',
    rolePermission: 'Role Permissions',
    super: 'Super administrator',
    admin: 'Administrator',
    optId: 'Operator ID',
    optUserId: 'Operation object ID',
    optType: 'Operation type',
    changeDiamond: 'Recharge diamond',
    changeCoin: 'Recharge coin',
    fixNickname: 'Change username',
    fixModifyName: 'Modified Name Count',
    fixSmsCode: 'Phone verification code',
    forbidChat: 'Forbid chat',
    forbidGame: 'Forbid game',
    chargeVip: 'VIP recharge',
    addVipExp: 'Modify VIP experience',
    saveNotice: 'Announce',
    updateNotice: 'Update announcement',
    deleteNotice: 'Delete announcement',
    sendMail: 'Send email',
    saveVersion: 'Save version number',
    updateVersion: 'Update version number',
    deleteVersion: 'Delete version number',
    versionId: 'Version ID',
    mailId: 'Mail ID',
    noticeId: 'Announcement ID',
    day: 'Day',
    optValue: 'Action',
    optAccount: 'Operator account',
    adminNotFind: 'No administrator account found',
    optLog: 'Operation log',
    deleteUser: 'This operation will permanently delete the account, do you want to continue?'
  },
  default: {
    money: 'Money',
    coin: 'Coin',
    diamond: 'Diamond',
    save: 'Save',
    add: 'Add',
    createTime: 'Create Time',
    updateTime: 'Update Time',
    operate: 'Operate',
    edit: 'Edit',
    delete: 'Delete',
    countLimit: 'Quantitative restrictions',
    success: 'Success',
    operateSuccess: 'Successful operation',
    operateFailed: 'Operation failed',
    sum: 'Sum',
    pass: 'Pass',
    reject: 'Reject',
    unhandler: 'Unprocessed',
    handler: 'Handle',
    invalidData: 'Invalid data',
    addNew: 'Add',
    cancel: 'Cancel',
    confirm: 'Confirm',
    optlog: 'Operation log',
    cancelDelete: 'Undeleted',
    tip: 'Prompt'
  }
};