import Layout from '@/layout/index.vue';
var operationRouter = {
  path: '/operation',
  component: Layout,
  meta: {
    title: 'operation',
    icon: 'example',
    roles: ['admin', '500', '300', '200', '400'],
    alwaysShow: true // will always show the root menu

  },
  children: [{
    path: 'goods',
    component: function component() {
      return import(
      /* webpackChunkName: "notice" */
      '@/views/operation/goods.vue');
    },
    name: 'goods',
    meta: {
      title: 'goods',
      roles: ['admin', '300']
    }
  }, {
    path: 'queryOrder',
    component: function component() {
      return import(
      /* webpackChunkName: "queryOrder" */
      '@/views/operation/queryOrder.vue');
    },
    name: 'queryOrder',
    meta: {
      title: 'queryOrder',
      roles: ['admin', '300', '500', '400'] // or you can only set roles in sub nav

    }
  }, {
    path: 'chatData',
    component: function component() {
      return import(
      /* webpackChunkName: "chatData" */
      '@/views/operation/chatData.vue');
    },
    name: 'chatData',
    meta: {
      title: 'chatData',
      roles: ['admin', '300', '500', '400'] // or you can only set roles in sub nav

    }
  }, {
    path: 'queryCharge',
    component: function component() {
      return import(
      /* webpackChunkName: "queryOrder" */
      '@/views/operation/queryCharge.vue');
    },
    name: 'queryCharge',
    meta: {
      title: 'queryCharge',
      roles: ['admin', '200'] // or you can only set roles in sub nav

    }
  }, {
    path: 'queryCharge1',
    component: function component() {
      return import(
      /* webpackChunkName: "queryOrder" */
      '@/views/operation/queryCharge1.vue');
    },
    name: 'queryCharge1',
    meta: {
      title: 'queryCharge1',
      roles: ['admin', '200'] // or you can only set roles in sub nav

    }
  }, {
    path: 'queryCharge2',
    component: function component() {
      return import(
      /* webpackChunkName: "queryOrder" */
      '@/views/operation/queryCharge2.vue');
    },
    name: 'queryCharge2',
    meta: {
      title: 'queryCharge2',
      roles: ['admin', '200'] // or you can only set roles in sub nav

    }
  }, {
    path: 'queryCharge3',
    component: function component() {
      return import(
      /* webpackChunkName: "queryOrder" */
      '@/views/operation/queryCharge3.vue');
    },
    name: 'queryCharge3',
    meta: {
      title: 'queryCharge3',
      roles: ['admin', '200'] // or you can only set roles in sub nav

    }
  }, {
    path: 'queryVoiceRoom',
    component: function component() {
      return import(
      /* webpackChunkName: "queryOrder" */
      '@/views/operation/voiceRoom.vue');
    },
    name: 'queryVoiceRoom',
    meta: {
      title: 'queryVoiceRoom',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'feedback',
    component: function component() {
      return import(
      /* webpackChunkName: "feedback" */
      '@/views/operation/feedback.vue');
    },
    name: 'feedback',
    meta: {
      title: 'feedback',
      roles: ['admin', '300', '400', '500'] // or you can only set roles in sub nav

    }
  }, {
    path: 'headerImage',
    component: function component() {
      return import(
      /* webpackChunkName: "headerImage" */
      '@/views/operation/headerImage.vue');
    },
    name: 'headerImage',
    meta: {
      title: 'headerImage',
      roles: ['admin', '300', '400', '500'] // or you can only set roles in sub nav

    }
  }, {
    path: 'bagMall',
    component: function component() {
      return import(
      /* webpackChunkName: "bagMall" */
      '@/views/operation/bagMall.vue');
    },
    name: 'bagMall',
    meta: {
      title: 'bagMall',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'discount',
    component: function component() {
      return import(
      /* webpackChunkName: "discount" */
      '@/views/operation/discount.vue');
    },
    name: 'discount',
    meta: {
      title: 'discount',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'gacha',
    component: function component() {
      return import(
      /* webpackChunkName: "discount" */
      '@/views/operation/gacha.vue');
    },
    name: 'gacha',
    meta: {
      title: 'gacha',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'limitActivity',
    component: function component() {
      return import(
      /* webpackChunkName: "limitActivity" */
      '@/views/operation/limitActivity.vue');
    },
    name: 'limitActivity',
    meta: {
      title: 'limitActivity',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'marquee',
    component: function component() {
      return import(
      /* webpackChunkName: "marquee" */
      '@/views/operation/marquee.vue');
    },
    name: 'marquee',
    meta: {
      title: 'marquee',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }]
};
export default operationRouter;