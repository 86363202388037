export default {
  route: {
    dashboard: '首页',
    dashboard1: '—首页—',
    profile: '个人中心',
    user: '玩家管理',
    user1: '—玩家管理—',
    userQuery: '查询玩家',
    vipUserQuery: 'vip查询',
    coinAbnormal: '金币异常',
    rankChange: '排行榜变化',
    sendGiftChange: '礼物记录',
    statManager: '统计管理',
    statManager1: '—统计管理—',
    logStat: '日志分析',
    logStat1: '—日志分析—',
    dailyReport: '日报',
    activity: '活动',
    fruitMachine: '水果机',
    laba: '拉霸',
    turn: '转盘',
    niuniu: '牛牛',
    voiceRoom: '语音房',
    taskStat: '任务统计',
    itemConsume: '道具消耗',
    dailyLife: '玩家日活',
    chargeStat: '充值统计',
    roomStat: '牌桌统计',
    itemStat: '道具统计',
    queryItem: '道具查询',
    agents: '分销商管理',
    agents1: '—分销商管理—',
    agentRegister: '注册链接',
    agentList: '分销商列表',
    cardRecord: '玩家数据查询',
    propRecord: '玩家物品查询',
    gameSetting: '游戏设置',
    gameSetting1: '—游戏设置—',
    notice: '公告',
    systemConfig: '游戏参数',
    userItemStat: '玩家道具统计',
    mail: '邮件',
    operation: '运营管理',
    operation1: '—运营管理—',
    version: '版本控制',
    goods: '商品管理',
    queryOrder: '订单查询',
    queryCharge: '新增用户的充值',
    queryCharge1: '安卓/ios新增充值',
    queryCharge2: '老用户召回',
    queryCharge3: '用户行为分析',
    queryVoiceRoom: '语音房管理',
    feedback: '反馈中心',
    headerImage: '审核头像',
    account: '帐号管理',
    account1: '—帐号管理—',
    accountList: '帐号列表',
    operateLog: '操作日志',
    nameRecord: '昵称记录',
    packageStock: '库存管理',
    bagMall: '背包商场',
    discount: '打折活动',
    gacha: '抽奖活动',
    bag: '背包',
    homePage: '主页',
    userReport: '用户',
    giveGiftList: '送礼排行榜',
    banner: 'Banner',
    blackList: '地区白名单',
    AF: 'AF关联充值和安装',
    limitActivity: '限时活动',
    marquee: '系统跑马灯',
    AFuser: 'AFuser',
    chatData: '聊天查询'
  },
  navbar: {
    logOut: '退出登录',
    dashboard: '首页',
    size: '布局大小'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '账号',
    password: '密码',
    passwordLen: '密码不能少于6位数字',
    inputUsername: '请输入帐号',
    loginFailed: '登录失败',
    loginNotFind: '帐号不存在',
    passwordWrong: '密码错误',
    accountNotActive: '帐号未激活',
    loginOK: '登录成功'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    showTagsView: '显示 Tags-View',
    showSidebarLogo: '显示侧边栏 Logo',
    fixedHeader: '固定 Header',
    sidebarTextTheme: '侧边栏文字主题色'
  },
  dashboard: {
    onlineRooms: '在线房间数',
    purchases: '总充值金额',
    shopping: '订单',
    orderId: '订单号',
    timestamp: '支付时间',
    status: '状态',
    price: '价格',
    productId: '商品号',
    type: '类型',
    count: '数量',
    registerCount: '注册数',
    loginCount: '登录数',
    clubRoom: '俱乐部房间',
    rooms: '普通房间',
    normalRooms: '普通模式',
    speedRooms: '快速模式',
    speedRoomClick: '快速模式(点击)',
    amount: '金钱',
    totalCount: '总数量',
    appleOrder: '苹果订单',
    totalOrder: '总订单',
    totalUser: '总用户数量',
    onlineUser: '实时在线人数',
    onlineTable: '在线对局数量',
    onlineAverage: '平均在线人数',
    onlineMax: '最高在线人数',
    totalAmount: '总充值金额',
    activeUsers: '活跃',
    newUsers: '新增',
    appleAmounts: '苹果金额',
    googleAmounts: '安卓金额',
    appleOrders: '苹果订单',
    googleOrders: '安卓订单',
    selectDate: '选择日期',
    amounts: '总金额',
    orders: '总订单'
  },
  user: {
    userInfo: '玩家信息',
    playerId: '玩家ID',
    headimgurl: '玩家头像',
    inputModifyHeadimgurl: '请输入头像',
    modifyHeadimgurl: '修改头像',
    inputPlayerId: '请输入玩家ID',
    inputPlayerId3: '请输入玩家ID2',
    inputPlayerId2: '请输入房间号',
    operate: '操作人',
    inputOperate: '请输入操作人账号',
    nickname: '玩家昵称',
    coin: '金币',
    chargeCoin: '充值金币',
    diamond: '钻石',
    other: '其他',
    chargeDiamond: '充值钻石',
    device: '设备号',
    ifToDeal: '是否处理',
    banedTime: '服务器时间',
    customerTime: '客户端时间',
    ip: 'ip',
    language: '系统语言',
    timezone: '时区',
    bancode: '封禁编码',
    loginToken: '登录码',
    session: '会话',
    sex: '性别',
    appid: 'appid',
    channel: '渠道号',
    publisher: '发布渠道',
    createTime: '创建时间(北京)',
    reset: '重置',
    inputChargeCount: '请输入充值数量',
    countLimit: '充值数量在-100000~100000之间',
    userNotExist: '该玩家不存在',
    beforeCount: '操作前',
    afterCount: '操作后',
    mobile: '手机号',
    phoneName: '机型',
    bindPhoneList: '绑定手机列表',
    from: '账号类型',
    logoutTime: '最后登出时间(北京)',
    loginTime: '最后登录时间(北京)',
    reports: '被举报次数',
    forbidTime: '时间：',
    forbidden: '封禁：',
    forbidChat: '禁言',
    forbidGame: '禁游',
    reportHours: '小时',
    unblock: '解封',
    ban: '封禁账号',
    banDevice: '封禁账号+设备',
    forbidChatDeadline: '禁言截止时间(北京)',
    forbidGameDeadline: '禁游截止时间(北京)',
    modifyName: '修改昵称',
    inputModifyName: '请输入昵称',
    modifyNameCount: '昵称修改次数',
    inputModifyNameCount: '请输入昵称修改次数',
    modifyCount: '修改次数',
    fixSmsCode: '修改验证码',
    inputSmsCode: '请输入验证码(4位数字)',
    smsCodeError: '验证码为4位数字',
    vip_7_day: '7天Vip',
    vip_30_day: '30天Vip',
    vipType: 'Vip类型',
    vipLevel: 'Vip等级',
    vipEndTime: 'Vip截止时间',
    vipCharge: 'Vip充值',
    vipExp: '修改vip经验值',
    vip_365_day: '365天vip',
    addVipExp: '增加vip经验值',
    level: '等级',
    exp: '经验值',
    inputExp: '修改经验值',
    addExp: '增加经验值',
    changeSex: '修改性别',
    role: '角色',
    oldName: '老昵称',
    newName: '新昵称',
    vipExpValue: 'vip经验值',
    totalCount: '总牌局',
    inputTotalCount: '请输入修改的总牌局数',
    modifyTotalCount: '修改牌局数'
  },
  agents: {
    updatePassword: '修改账号密码',
    inputPassword: '请输入密码',
    confirm: '确认',
    reset: '重置',
    passwordLen: '密码长度不能小于6位',
    updatePasswordFailed: '修改密码失败',
    createLink: '注册链接',
    agentType: '分销商类型',
    createAgentRegister: '生成注册链接',
    createLinkAndCopy: '请先生成注册链接，然后在复制......',
    selectAgentType: '请选择分销商类型',
    copy: '复制',
    distributor: '分销商',
    CreateLinkFailed: '注册链接失败',
    inputAgentAccount: '请输入分销商账号',
    search: '搜索',
    id: '编号',
    account: '用户名',
    password: '密码',
    registerTime: '注册时间',
    lastTime: '最近登录时间',
    coinCount: '金币数量',
    diamondCount: '钻石数量',
    operate: '操作',
    recharge: '充值',
    chargeRecord: '买卡记录',
    activate: '激活',
    unactive: '禁用',
    modifyPassword: '修改密码',
    chargeType: '类型',
    selectChargeType: '请选择类型',
    count: '数量',
    chargedId: '充值帐号ID',
    orderId: '订单号',
    operateType: '操作类型',
    chargeBefore: '充值前',
    chargeAfter: '充值后',
    chargeTime: '充值时间',
    cancel: '取消',
    inputChargeCount: '请输入充值数量',
    inputCountIn100000: '请输入-100000~100000之间',
    idNotEmpty: 'ID不能为空',
    accountNotEmpty: '用户名不能为空',
    passwordNotEmpty: '密码不能为空',
    playerNotExist: '玩家不存在',
    selectOne: '请先选择一个用户',
    admin: '超级管理员',
    service: '客服',
    agent_1: '一级分销商',
    agent_2: '二级分销商',
    promoter: '推广员',
    player: '玩家',
    chargeCard: '充卡记录',
    sellCard: '售卡记录',
    outId: '卖出账号',
    inId: '买入账号',
    buyDiamond: '买钻石',
    buyCoin: '买金币',
    giftDiamond: '赠钻石',
    giftCoin: '赠金币',
    chargeCoin: '充金币',
    chargeDiamond: '充钻石',
    playerId: '玩家ID'
  },
  statManager: {
    packType: '礼包种类',
    allBuyCount: '总购买数量',
    newBuyUser: '新增购买人数',
    allBuyUser: '所有购买人数',
    refreshTime: '总刷新次数',
    roomType: '选择牌桌类型',
    redPackFail: '红包失败记录',
    weeklyGift: '每周礼包',
    randomGift: '随机打折',
    discountPack: '打折限定周卡绑定',
    vipPack: 'vip礼包',
    nestedTurn: '套包转盘',
    niuniu1: '普通场',
    niuniu2: '高级场',
    voiceRoom: '房间统计',
    voiceGift: '礼物统计',
    voiceGame: '小游戏统计',
    isSystem: '是否系统发送',
    failReason: '失败原因',
    systemBuyCount: '系统购买商品个数',
    redPack: '全服红包',
    weekPack: '每周礼包',
    allCandC: '全服充值消耗',
    day3: '3日',
    day7: '7日',
    day14: '14日',
    nextDay: '次日',
    today: '当日',
    program: '项目',
    day30: '30日',
    rest: '查留存和付费',
    totalUser: '总人数',
    onlineRooms: '在线时长',
    newUsers: '新注册人数',
    loginUsers: '登录人数',
    avrgTime: '平均在线时长',
    maxTime: '最大在线时长',
    minTime: '最小在线时长',
    userOrder: '支付人数',
    totalOrder: '总订单数',
    appleOrder: '苹果订单数',
    googleOrder: '谷歌订单数',
    diamondOrder: '钻石订单数',
    coinOrder: '金币订单数',
    totalOrderCnt: '订单总数',
    hanks: '总局数',
    totalPlayers: '参与总人数',
    clubRoom: '俱乐部房间数',
    albCoins: '游戏一',
    albUpGrade: '游戏二',
    albPDK: '游戏三',
    room4Person: '4人房',
    room5Person: '5人房',
    room6Person: '6人房',
    room7Person: '7人房',
    room8Person: '8人房',
    albCoinsUids: '游戏一参与人数',
    albUpGradeUids: '游戏二参与人数',
    albPDKUids: '游戏三参与人数',
    optType1: '对局消耗',
    optType2: '转盘获得',
    optType3: '好友赠送',
    optType4: '苹果',
    optType5: '谷歌',
    optType6: 'web',
    optType7: '代理充值',
    optType8: '后台充值',
    optType9: '新号获得',
    optType10: '兑换金币',
    optType12: '反馈获得',
    optType13: '后台获得',
    optType100: '默认',
    date: '日期',
    type: '道具类型',
    optType: '记录类型',
    useType: '使用类型',
    startTime: '开始时间',
    endTime: '结束时间',
    isAlert: '是否弹出',
    search: '查询',
    searchReport: '查询举报聊天',
    export: '导出',
    to: '到',
    serial3days: '3天日活',
    serial7days: '7天日活',
    optType14: '活动获得',
    optType15: '系统赠送',
    optType16: '排行榜获得',
    optType17: '赠送金币',
    optType18: '道具折换',
    optType19: '购买道具',
    optType20: '天梯获得',
    optType21: '天梯消耗',
    optType22: '天梯战力奖励',
    provider: '渠道',
    originalId: '游戏订单ID',
    state: '订单状态',
    firstChargeTip: '首次充值',
    firstCharge: '首次',
    orderId: '渠道订单ID',
    goodsId: '商品ID',
    amount: '金额($)',
    count: '数量',
    sar: '阿拉伯金额',
    createTime: '发起时间',
    updateTime: '更新时间',
    stateInit: '订单发起',
    stateSuccess: '订单成功',
    stateFail: '订单失败',
    stateCannel: '订单取消',
    unfirstCharge: '非首次',
    inputGoodsId: '请输入商品ID',
    inputOriginalId: '请输入游戏订单ID',
    inputOrderId: '请输入渠道订单ID',
    optType23: 'vip赠送',
    optType24: '绑定赠送',
    optType25: '小游戏消耗',
    optType26: '小游戏获奖',
    optType27: '天梯获胜奖励',
    optType28: '购买昵称次数',
    optType29: '开启宝箱消耗',
    optType30: '宝箱奖励',
    optType31: '争霸赛消耗',
    optType32: '争霸赛奖励',
    optType33: '扭蛋消耗',
    optType34: '扭蛋奖励',
    optType36: '补签',
    optType37: '引导',
    optType38: '扭蛋奖励返还',
    optType39: '补单',
    optType40: '水果机排行榜',
    optType41: '失败领取金币',
    optType42: '转盘消耗',
    optType43: '转盘消耗奖励',
    optType44: '水果机消耗',
    optType45: '水果机获得',
    optType46: '登录新年礼包',
    optType47: '充值返利',
    optType48: '蛇与梯子',
    optType49: '全服红包',
    optType50: '每天免费金币和钻石',
    optType51: '周礼包-领取',
    optType52: '随机礼包',
    optType53: '极速模式消耗',
    optType54: '极速模式获得',
    optType55: '牛牛排行榜',
    optType56: '牛牛消耗',
    optType57: '牛牛获得',
    optType58: '全服充值',
    optType59: '全服消耗',
    optType60: '对局活动奖励',
    optType61: '入场动画VIP特权商品购买消耗钻石',
    optType62: '限时礼包-领取',
    optType63: '在线钻石',
    optType64: '回归系统',
    optType65: '加入语音房消耗',
    optType66: '语音房购买道具',
    optType67: '房主收益',
    optType68: '运营活动相关',
    optType69: '水果机点数',
    optType70: '小游戏消耗',
    optType71: '小游戏房主收益',
    optType72: '礼物转盘消耗',
    optType73: '礼物转盘奖励',
    optType74: '拉霸获得',
    optType75: '拉霸消耗',
    optType10000: '每日资产汇总',
    optType10001: '30日内活跃资产汇总',
    fruitMachine: '活动详细',
    fruitMachine2: '活动汇总',
    turnCard: '翻牌子',
    getBox: '宝箱',
    paramConfig: '参数设置',
    awardPlayers: '领奖人数',
    awardAmount: '领奖金额',
    onlyMedia_source: '只看渠道',
    onlyAd: '只看Bi',
    onlyOrganic: '只看来源',
    onlyAndAd: '渠道+代理',
    onlyAll: '全部',
    player: '玩家统计',
    banker: '上庄人数',
    sysBankerTime: '系统庄次数',
    bankerTime: '玩家庄次数',
    partycipate: '参与人数',
    beton: '下注金币',
    winCoin: '赢取金币',
    bankerWinCoin: '庄家赢取',
    bankerLoseCoin: '庄家输掉',
    sysBankerWinCoin: '系统庄赢取',
    sysBankerLoseCoin: '系统庄输掉',
    robotBankerWin: '系统庄累计赢取',
    luckyCoin: '幸运星产出',
    rankCoin: '排行榜产出',
    sysGet: '系统回收',
    luckyTime: '幸运星返奖次数',
    luckyPool: '幸运星奖池',
    rankPool: '排行榜奖池',
    organic: '来源',
    os: '系统',
    num0: '当日新增',
    amount0: '充值',
    num1: '1日留存',
    amount1: '1日充值',
    num2: '2日留存',
    amount2: '2日充值',
    num3: '3日留存',
    amount3: '3日充值',
    num7: '7日留存',
    amount7: '7日充值',
    num15: '15日留存',
    amount15: '15日充值',
    num30: '30日留存',
    amount30: '30日充值',
    num60: '60日留存',
    amount60: '60日充值',
    num90: '90日留存',
    amount90: '90日充值',
    amount120: '120日充值',
    amount180: '180日充值',
    coinProduct: '产出',
    coinconsume: '消耗',
    threeTimes: '三连次数',
    isDouble: '双倍次数',
    isHighBase: '高倍次数',
    restOfPool: '奖池剩余数',
    stateReissue: '补单',
    stateinapp: 'in_app为空',
    stateproduct: '商品不在列表中',
    stateOther: '未知状态',
    badGateway: 'Bad Gateway',
    giveUid: '被赠送者ID',
    peopleNumber: '人次',
    peopleCount: '人数',
    succActivityNum: '成功参与人数',
    pointContent: '打点内容',
    activityClass1: '首充特惠',
    activityClass2: '礼袋类',
    xingyunqiandai_2: '幸运钱袋2',
    xingyunqiandai_1: '幸运钱袋1',
    leichong: '累充活动',
    allCharge: '全服充值',
    allConsume: '全服消耗',
    holiday: '小时礼包',
    viplibao: 'vip礼包',
    weeklibao: '周礼包',
    firstRecharge: '首充',
    zhouka: '周卡',
    playCountConfig: '对局数',
    niuniuTime: '牛牛排行榜',
    buySixGetOne: '6送1',
    chargeAndTurn: '累充翻牌',
    laba: '拉霸',
    nestedPackage: '套包',
    nestedPackage1: '套包定制',
    customedPackage: '定制礼包',
    voiceDiamond: '语音房钻石',
    times1: '1倍参与数',
    times5: '5倍参与数',
    times10: '10倍参与数',
    times20: '20倍参与数',
    times50: '50倍参与数',
    times100: '100倍参与数',
    shareTimes: '分享参与次数',
    shareSuccess: '成功分享次数',
    exportProp: '导出产生的道具',
    room: '房间',
    cardFace: '卡面',
    entrance: '入口',
    beforeDiscountNumber: '打折前人数',
    afterDiscountNumber: '打折后人数',
    banner: 'banner',
    loginMethod: '登录方式',
    login: '登录',
    inputGiftId: '请输入礼物ID',
    giftCount: '送礼个数',
    rank: '名次',
    Xinzeng: '新增',
    Rihuo: '日活',
    Fufeirenshu: '付费人数',
    Xinzengfufeirenshu: '新增付费人数',
    Fufeijiner: '付费金额',
    Xinzengfufeijiner: '新增付费金额',
    Fufeilv: '付费率',
    Xinzengfufeilv: '新增付费率',
    Arpu: 'arpu',
    Arppu: 'arppu',
    Vip7day: 'Vip7',
    Vip30day: 'Vip30',
    Vip365day: 'Vip365',
    gachaRewardCount: '扭蛋参与人数',
    media_source: '媒体来源',
    more: '更多',
    supplement: '补单',
    xinnianlibao: '节日礼包（充值）',
    peopleClientNumber: '入口点击人数',
    peopleBuyNumber: '购买人数',
    buyPruductNumber: '购买商品个数',
    battlePass: '集会',
    diamondConsume: '钻石消耗数',
    drawLotteryCount: '摇奖次数',
    shareCount: '分享次数',
    gearsKind: '档位种类',
    buyCount: '购买个数',
    buyPersonCount: '购买人数',
    mysticalTreasures: '神秘宝藏',
    mysticalTreasuresCount: '神秘宝藏总数统计',
    mysticalTreasuresGear: '神秘宝藏充值档位统计'
  },
  gameSetting: {
    remark: '备注',
    inputRemark: '请输入备注',
    level: '权重',
    inputLevel: '请输入权重',
    add: '添加',
    activity: '公告内容',
    time: '创建时间',
    updateTime: '更新时间',
    operate: '操作',
    edit: '编辑',
    delete: '删除',
    notice: '公告',
    notEmpty: '公告不能为空',
    save: '保存',
    addAndEdit: '添加或编辑公告信息',
    addAndEditBanner: '添加或编辑Banner信息',
    title: '标题',
    inputTitle: '请输入标题',
    version: '版本号',
    inputVersion: '请输入版本号',
    type: '类型',
    isShelf: '是否立即上架',
    inputType: '请输入类型',
    selectIsShelf: '选择是否上架',
    text: '文本',
    image: '图片',
    bannerType: 'banner标识',
    inputBannerType: '请选择banner标识',
    shopBanner: '商城banner',
    newInRank: '新上榜',
    voiceBanner: '语音房banner',
    battlePassBanner: '集会banner',
    vipBanner: 'vipbanner',
    normalBanner: '普通banner',
    imageUrl: '图片地址',
    inputImageUrl: '请输入图片地址',
    mailTime: '发送时间(沙特)',
    addMail: '发送邮件',
    mailContent: '邮件内容',
    inputMailContent: '请输入邮件内容',
    allUser: '全服玩家',
    users: '某些玩家',
    uids: '玩家ids',
    inputMailUids: '请输入玩家id, 英文逗号分隔',
    noGiveItem: '不送金币或者钻石',
    itemType: '道具类型',
    itemId: '道具ID',
    itemCount: '道具数量',
    timeRange: '道具时效',
    emailTemplate: '邮件模板',
    askForInvoice: '索要玩家发票',
    replacementProp: '玩家补发道具',
    chargeFeedBack: '充值到账反馈用户',
    offerOrderDetail: '玩家提供详细订单',
    cannotLogin: '索要玩家无法登录的截图',
    fullchargeBug: '充值排行榜bug',
    rankreward: '排行榜活动补发',
    fbloginerr: 'face book 无法登录',
    needMoreOrder: '需要玩家提供多个订单',
    selectEmailTemplate: '请选择邮件模板',
    inputItemCount: '请输入道具数量',
    selectItemType: '请选择道具类型',
    inputItemType: '请输入道具ID',
    activityConfig: '配置日期',
    activity1: '活动1',
    inputactivity1: '请输入活动1',
    activity2: '活动2',
    inputactivity2: '请输入活动2',
    activity3: '活动3',
    inputactivity3: '请输入活动3',
    inputItemTimeRange: '请输入道具时效，有效天数',
    jumpUrl: '跳转地址',
    period: '公告时效',
    inputPeriod: '请输入公告时效',
    isShelfPeriod: '上架时间',
    yes: '是',
    no: '否',
    batchDeal: '切换到批量处理',
    batchDealPass: '批量通过',
    batchDealdeny: '批量拒绝',
    batchDealPassAndDeny: '批量处理',
    times: '出现时机',
    once: '仅1次',
    day: '一天一次',
    always: '一直',
    selectTimesType: '请选择出现时机',
    place: '出现位置',
    selectPlaceType: '请选择出现位置',
    isRestart: '是否需要重启',
    isShowSend: '是否显示送礼者',
    vipFreeExTimes: 'vip免费次数',
    isDelaySend: '是否定时发送',
    restartTime: '重启时间(阿拉伯)',
    selectRestartTime: '选择重启时间',
    login: '登录界面',
    hall: '大厅界面',
    ios_version: '苹果版本号',
    inputIOSVersion: '请输入苹果版本号',
    inputAndroidVersion: '请输入安卓版本号',
    android_version: '安卓版本号',
    addAndEditVerion: '添加或编辑版本',
    fb_type: '反馈类型',
    fb_report: '举报',
    fb_feedback: '客服中心',
    fb_content: '内容',
    fb_reportTypes: '问题类型',
    fb_mail: '邮件',
    fb_images: '图片',
    fb_reportUid: '被举报玩家ID',
    fb_state: '状态',
    fb_createTime: '创建时间',
    fb_updateTime: '处理时间',
    fb_init: '未处理',
    blackList: '黑名单',
    whiteList: '白名单',
    fb_handle: '处理',
    fb_nothandle: '不处理',
    fb_reject: '拒绝',
    fb_reportType1: '游戏问题',
    fb_reportType2: '游戏建议',
    fb_reportType3: '支付问题',
    fb_reportType4: '封禁申诉',
    fb_reportType5: '其他',
    fb_reportType6: '恶意广告',
    fb_reportType7: '辱骂',
    fb_reportType8: '淫秽',
    fb_reportType9: '其他',
    inputReportUid: '请输入被举报玩家ID',
    fb_showDetail: '显示详情',
    addStockTypeTip: '增加货币或道具类型',
    propOrCurrencyID: '货币或道具ID',
    propOrCurrencyCount: '货币或道具数量',
    inputPropOrCurrencyID: '请输入货币或道具ID',
    inputPropOrCurrencyCount: '请输入货币或道具数量',
    propOrCurrencyCountError: '数量必须大于0',
    inputSameId: '请勿添加重复ID',
    rechargeStock: '充值库存',
    rechargeCount: '充值数量',
    inputRechargeCount: '请输入充值数量',
    propOrCurrencyCountError2: '数量必须大于0',
    notExistItemStock: '货币或道具ID不存在',
    deleteStock: '此操作将永久删除该货币或道具ID, 是否继续?',
    setWarnThreshold: '设置预警阀值',
    firstThreshold: '第一预警阀值',
    secondThreshold: '第二预警阀值',
    inputFirstThreshold: '请输入第一阀值',
    inputSecondThreshold: '请输入第二阀值',
    ThresholdError: '阀值必须大于0',
    warnUser: '预警通知人',
    addWarnUser: '添加库存预警通知人',
    inputWarnUser: '请输入预警通知人的手机号，多个用逗号隔开',
    NotExistStockUser: '库存预警阀值通知人不存在',
    selectType: '请选择类型',
    inputJumpUrl: '请输入跳转url'
  },
  operation: {
    id: '任务编号',
    minutes: '发送倒计时(分钟)',
    level1: '奖励一充值金额（分）',
    coin1: '奖励一返奖金币',
    money1: '奖励一返奖钻石',
    item1: '奖励一返奖itemId',
    days1: '奖励一返奖item天数',
    count1: '奖励一返奖item个数',
    level2: '奖励二充值金额（分）',
    coin2: '奖励二返奖金币',
    money2: '奖励二返奖钻石',
    item2: '奖励二返奖itemId',
    days2: '奖励二返奖item天数',
    count2: '奖励二返奖item个数',
    level3: '奖励三充值金额（分）',
    coin3: '奖励三返奖金币',
    money3: '奖励三返奖钻石',
    item3: '奖励三返奖itemId',
    days3: '奖励三返奖item天数',
    count3: '奖励三返奖item个数',
    title: '标题',
    context: '内容',
    isTimeLimit: '是否限时',
    imagePath: '图片地址',
    isImgCanTouch: '图片是否可点击',
    btnType: '按钮类型',
    vip1: 'vip',
    tehui: '特惠',
    limit1: '限时礼包',
    text: '公告',
    leichong: '累充',
    random: '随机打折',
    activityEntrance: '活动入口',
    activityType: '活动类型',
    newYearGift: '登录礼包活跃',
    holidayGift: '节日礼包（充值）',
    addAndEdit: '新增或修改商品',
    expression: '对局表情',
    gift: '刷屏礼物',
    voiceRoom: '语音房礼物',
    itemGift: '语音房道具礼物',
    props: '商城道具',
    productType: '商品类型',
    selectProductType: '请选择商品类型',
    selectRankType: '请选择排行类型',
    productName: '商品名称',
    inpurtProductName: '请输入商品名称',
    codeName: '程序名字',
    inpurtCodeName: '请输入程序名字',
    costType: '消耗类型',
    selectCostType: '请选择消耗类型',
    productPrice: '商品价格',
    inputProductPrice: '请输入商品价格',
    charmValue: '魅力值',
    inputCharmValue: '请输入魅力值',
    productDesc: '商品说明',
    productDesc2: '售前说明',
    productDesc3: '购前描述',
    productProd: '产出途径',
    inputProductDesc: '请输入商品说明',
    inputProductProd: '请输入产出途径',
    timeType: '时间类型',
    deadline: '截止时间',
    period: '时间段',
    selectDatetime: '选择时间',
    gameProps: '游戏道具',
    productStatus: '商品状态',
    selectProductStatus: '请选择商品状态',
    onShelf: '上架',
    offShelf: '下架',
    tentative: '暂定',
    inputRefuseContent: '请输入拒绝的内容',
    vipType: 'VIP类型',
    selectVipType: '请选择VIP类型',
    all: '全部',
    vip: '仅限VIP',
    notvip: '仅限非VIP',
    zhuanshu: '语音房专属',
    periodType: '时效类型',
    selectPeriodType: '请选择时效类型',
    forever: '永久生效',
    limit: '限时生效',
    days: '天数',
    selectDays: '选择天数',
    day7: '7天',
    day30: '30天',
    activityProps: '活动道具',
    recoveryPrice: '回收价格',
    inputRecoveryPrice: '请输入回收价格',
    bagMall: '背包商场',
    photoFrame: '头像框',
    dataCard: '资料卡',
    tablecloth: '桌布',
    poker: '纸牌',
    associatedFields: '关联字段',
    inpurtAssociatedFields: '请输入关联字段',
    purchaseType: '购买类型',
    selectPurchaseType: '请选择购买类型',
    weight: '商品权重',
    inputWeight: '请输入商品权重',
    inputVipFreeExTimes: '请输入次数',
    day1: '1天',
    day3: '3天',
    discount: '打折活动',
    vip_7_day: 'Vip7天',
    vip_30_day: 'Vip30天',
    vip_365_day: 'Vip365天',
    vipClose: '关闭',
    vip7Open: '开启 赠送3天',
    selectVipState: '请选择vip状态',
    vip30Open: '开启 赠送15天',
    vip365Open: '开启 赠送365天',
    openDate: '开启日期',
    closeDate: '关闭日期',
    openTime: '开启时间',
    closeTime: '关闭时间',
    addAndEditVipDiscount: '修改打折活动',
    operationTime: '操作时间',
    isOpen: '是否开启',
    isValid: '是否启用vip365',
    valid: '启用',
    invalid: '禁用',
    giveDuration: '赠送时长',
    operator: '操作人',
    StartGreaterThanEnd: '开启时间不能晚于关闭时间',
    buyStatus: '购买状态',
    selectBuyStatus: '选择购买状态',
    buy: '可以购买',
    nobuy: '不可购买',
    addAndEditGacha: '修改抽奖活动',
    count: '数量',
    costDiscount: '折扣',
    inputCountCount: '请输入消耗数量',
    inputCostDiscount: '请输入折扣',
    reportDate: '报告日期',
    mustLessThanOne: '必须是0到1之间的小数',
    rankActivity: '排行榜',
    voiceRankGift: '语音房礼物',
    rankType: '排行榜类型',
    inputActivityType: '请选择活动类型',
    rankActivity10: '送礼排行榜',
    rankActivity11: '充值排行榜',
    rankActivity12: '消耗排行榜',
    rankActivity15: '魅力排行榜',
    rankActivity23: '牛牛排行榜',
    rankActivity27: 'rankActivity27',
    rankActivity28: 'rankActivity28',
    rankActivity29: 'rankActivity29',
    rewardDate: '获奖时间',
    relationItemId: '关联itemId',
    filter: '类型',
    marqueeContent: '跑马灯',
    marqueeNotEmpty: '跑马灯不能为空',
    marqueeLevel: '级别',
    inputMarqueeLevel: '请输入级别',
    repeatTimes: '重复次数',
    inputRepeatTimes: '请输入重复次数',
    battleTypeCur: '本期',
    battleTypeNext1: '下期第一期',
    battleTypeNext2: '下期第二期',
    chatType: '聊天类型',
    reportId: '被举报ID',
    chatContent: '聊天内容',
    inWorldChat: '世界聊天',
    inRoomChat: '房间内聊天',
    friendChat: '好友聊天',
    reasontype: '问题类型',
    words: '关键字'
  },
  errorTips: {
    dateLimitError: '日期超过30天',
    getDataFail: '获取数据失败'
  },
  roles: {
    account: '帐号管理人',
    accountNotEmpty: '帐号管理人不能为空',
    depart: '部门',
    selectDeparts: '请选择部门',
    technology: '本地化',
    product: '市场部',
    operation: '客服运营',
    design: '策划部',
    manager: '管理',
    selectRoles: '请选择角色权限',
    rolePermission: '角色权限',
    super: '超级管理员',
    admin: '管理员',
    support: '客服人员',
    optId: '操作者ID',
    optUserId: '操作对象ID',
    optType: '操作类型',
    changeDiamond: '充值钻石',
    changeCoin: '充值金币',
    fixNickname: '修改昵称',
    fixModifyName: '修改改名次数',
    fixSmsCode: '手机验证码',
    forbidChat: '禁言',
    forbidGame: '禁游',
    chargeVip: 'vip充值',
    addVipExp: '修改vip经验值',
    saveNotice: '发布公告',
    updateNotice: '更新公告',
    deleteNotice: '删除公共',
    sendMail: '发送邮件',
    saveVersion: '保存版本号',
    updateVersion: '更新版本号',
    deleteVersion: '删除版本号',
    updateStock: '更新道具数量',
    setStockWarn: '设置库存预警阀值',
    setAndUpdateVipDiscount: '设置和更新VIP折扣',
    saveBanner: '设置banner',
    deleteBanner: '删除banner',
    updateBanner: '更新banner',
    fixHeadImgUrl: '修改头像',
    deleteStock: '删除道具ID',
    saveStock: '增加新的道具ID',
    versionId: '版本ID',
    mailId: '邮件ID',
    noticeId: '公告ID',
    day: '天',
    optValue: '动作',
    optAccount: '操作者帐号',
    adminNotFind: '未找到管理员帐号',
    optLog: '操作日志',
    deleteUser: '此操作将永久删除该帐号, 是否继续?'
  },
  default: {
    money: '钱',
    coin: '金币',
    diamond: '钻石',
    save: '保存',
    add: '添加',
    startDate: '开始日期',
    stopDate: '结束日期',
    createTime: '创建时间',
    updateTime: '更新时间',
    operate: '操作',
    edit: '编辑',
    delete: '删除',
    countLimit: '数量限制',
    success: '成功',
    operateSuccess: '操作成功',
    operateFailed: '操作失败',
    sendSuccess: '发送成功',
    sendFailed: '发送失败',
    query: '查询',
    search: '搜索',
    today: '今天',
    yesterday: '昨天',
    aWeekAgo: '一周前',
    lastWeek: '最近一周',
    lastMonth: '最近一个月',
    sum: '合计',
    pass: '通过',
    reject: '拒绝',
    forbid1: '解封',
    forbid2: '封禁',
    unhandler: '未处理',
    handler: '处理',
    invalidData: '无效数据',
    addNew: '新增',
    cancel: '取消',
    confirm: '确定',
    optlog: '操作日志',
    cancelDelete: '已取消删除',
    tip: '提示',
    recharge: '充值',
    warn: '设置预警',
    selectedExport: '导出已选择项',
    enterExcelName: 'Please enter the file name (default excel-list)'
  }
};