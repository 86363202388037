import Cookies from 'js-cookie'; // App

var sidebarStatusKey = 'manager_tools_sidebar_status';
export var getSidebarStatus = function getSidebarStatus() {
  return Cookies.get(sidebarStatusKey);
};
export var setSidebarStatus = function setSidebarStatus(sidebarStatus) {
  return Cookies.set(sidebarStatusKey, sidebarStatus);
};
var languageKey = 'manager_tools_language';
export var getLanguage = function getLanguage() {
  return Cookies.get(languageKey);
};
export var setLanguage = function setLanguage(language) {
  return Cookies.set(languageKey, language);
};
var sizeKey = 'manager_tools_size';
export var getSize = function getSize() {
  return Cookies.get(sizeKey);
};
export var setSize = function setSize(size) {
  return Cookies.set(sizeKey, size);
}; // User

var tokenKey = 'manager_tools_token';
export var getToken = function getToken() {
  return Cookies.get(tokenKey);
};
export var setToken = function setToken(token) {
  return Cookies.set(tokenKey, token);
};
export var removeToken = function removeToken() {
  return Cookies.remove(tokenKey);
};