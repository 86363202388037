import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/home/mdagsh/tools/tools/frontend/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLanguage } from '@/utils/cookies'; // element-ui built-in lang

import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';
import elementArLocale from 'element-ui/lib/locale/lang/ar'; // User defined lang

import enLocale from "./en";
import zhLocale from "./zh";
import arLocale from "./ar";
Vue.use(VueI18n);
var messages = {
  en: _objectSpread({}, enLocale, {}, elementEnLocale),
  zh: _objectSpread({}, zhLocale, {}, elementZhLocale),
  ar: _objectSpread({}, arLocale, {}, elementArLocale)
};
export var getLocale = function getLocale() {
  var cookieLanguage = getLanguage();

  if (cookieLanguage) {
    return cookieLanguage;
  }

  var language = navigator.language.toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  } // Default language is english


  return 'en';
};
var i18n = new VueI18n({
  locale: getLocale(),
  messages: messages
});
export default i18n;