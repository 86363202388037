import Layout from '@/layout/index.vue';
var accountRouter = {
  path: '/account',
  component: Layout,
  name: 'account',
  meta: {
    title: 'account',
    icon: 'peoples',
    alwaysShow: true,
    roles: ['100']
  },
  children: [{
    path: 'list',
    component: function component() {
      return import(
      /* webpackChunkName: "accountList" */
      '@/views/account/List.vue');
    },
    name: 'accountList',
    meta: {
      title: 'accountList',
      roles: ['100'],
      noCache: true
    }
  }, {
    path: 'operateLog',
    component: function component() {
      return import(
      /* webpackChunkName: "operateLog" */
      '@/views/account/operateLog.vue');
    },
    name: 'operateLog',
    meta: {
      title: 'operateLog',
      roles: ['100'],
      noCache: true
    }
  }]
};
export default accountRouter;