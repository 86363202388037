export default {
  route: {
    dashboard: 'منزل',
    profile: 'مركز شخصي',
    user: 'إدارة اللاعب',
    userQuery: 'لاعب الاستعلام',
    statManager: 'الإدارة الإحصائية',
    dailyLife: 'يوم اللاعب',
    chargeStat: 'إحصائيات إعادة الشحن',
    roomStat: 'إحصائيات الجدول',
    itemStat: 'إحصائيات البند',
    queryItem: 'استفسار البند',
    agents: 'إدارة الموزعين',
    agentRegister: 'رابط التسجيل',
    agentList: 'قائمة الموزعين',
    cardRecord: 'سجلات البند',
    gameSetting: 'إعدادات اللعبة',
    notice: 'إعلان',
    mail: 'بريد',
    operation: 'إدارة العمليات',
    version: 'التحكم في الإصدار',
    goods: 'إدارة البضائع',
    queryOrder: 'تتبع الطلب',
    feedback: 'مركز الملاحظات',
    headerImage: 'مراجعة الصورة الرمزية',
    account: 'ادارة الحساب',
    accountList: 'قائمة الحسابات',
    operateLog: 'سجل العمليات',
    nameRecord: 'سجل اللقب'
  },
  navbar: {
    logOut: 'تسجيل الخروج',
    dashboard: 'منزل',
    size: 'حجم التخطيط'
  },
  login: {
    title: 'تسجيل دخول النظام',
    logIn: 'تسجيل الدخول',
    username: 'رقم الحساب',
    password: 'كلمة المرور',
    passwordLen: 'لا يمكن أن تكون كلمة المرور أقل من 6 أرقام',
    inputUsername: 'الرجاء إدخال رقم الحساب',
    loginFailed: 'فشل تسجيل الدخول',
    loginNotFind: 'الحساب غير موجود',
    passwordWrong: 'كلمة مرور خاطئة',
    accountNotActive: 'الحساب غير مفعل',
    loginOK: 'تم تسجيل الدخول بنجاح'
  },
  tagsView: {
    refresh: 'تحديث',
    close: 'قريب',
    closeOthers: 'أغلق الآخر',
    closeAll: 'أغلق الكل'
  },
  settings: {
    title: 'تكوين تخطيط النظام',
    theme: 'لون الموضوع',
    showTagsView: 'عرض Tags-View',
    showSidebarLogo: 'إظهار الشريط الجانبي Logo',
    fixedHeader: 'ثابت Header',
    sidebarTextTheme: 'لون موضوع نص الشريط الجانبي'
  },
  dashboard: {
    totalUser: 'العدد الإجمالي للأشخاص',
    onlineRooms: 'الوقت على الانترنت',
    purchases: 'نقود',
    shopping: 'نموذج طلبية',
    orderId: 'رقم الطلب',
    timestamp: 'وقت الدفع',
    status: 'دولة',
    price: 'السعر',
    productId: 'رقم المنتج',
    type: 'نوع',
    count: 'كمية',
    registerCount: 'عدد التسجيلات',
    loginCount: 'عدد تسجيلات الدخول',
    clubRoom: 'غرفة النادي',
    rooms: 'غرفة عادية',
    amount: 'نقود',
    totalCount: 'الكمية الإجمالية',
    appleOrder: 'ترتيب أبل',
    totalOrder: 'الترتيب الكلي',
    amounts: 'المبلغ الإجمالي',
    orders: 'إجمالي الطلب'
  },
  user: {
    userInfo: 'معلومات اللاعب',
    playerId: 'معرف اللاعب',
    inputPlayerId: 'الرجاء إدخال معرف اللاعب',
    nickname: 'اسم اللاعب',
    coin: 'عملة ذهبية',
    chargeCoin: 'اشحن العملات الذهبية',
    diamond: 'الماس',
    chargeDiamond: 'أعلى متابعة الماس',
    device: 'رقم الجهاز',
    loginToken: 'كود تسجيل الدخول',
    session: 'محادثة',
    sex: 'جنس',
    appid: 'APPID',
    channel: 'رقم القناة',
    publisher: 'قناة النشر',
    createTime: 'وقت الخلق',
    reset: 'إعادة تعيين',
    inputChargeCount: 'الرجاء إدخال عدد عمليات إعادة الشحن',
    countLimit: 'يتراوح عدد الشحنات بين 100000 و 100000.',
    userNotExist: 'اللاعب غير موجود',
    beforeCount: 'قبل العملية',
    afterCount: 'بعد العملية',
    mobile: 'رقم الهاتف',
    from: 'نوع الحساب',
    logoutTime: 'آخر وقت تسجيل خروج',
    loginTime: 'وقت تسجيل الدخول الأخير',
    reports: 'أفادت',
    forbidTime: 'الوقت:',
    forbidden: 'الحظر:',
    forbidChat: 'ممنوع',
    forbidGame: 'لا سفر',
    reportHours: 'ساعات',
    unblock: 'إلغاء الحظر',
    ban: 'حظر',
    forbidChatDeadline: 'الموعد النهائي للتجاهل',
    forbidGameDeadline: 'لا يوجد موعد نهائي للسفر',
    modifyName: 'غير اسم المستخدم',
    inputModifyName: 'يرجى إدخال اسم مستعار',
    modifyNameCount: 'أوقات تعديل اللقب',
    inputModifyNameCount: 'الرجاء إدخال أوقات تعديل اللقب',
    modifyCount: 'عدد التعديلات',
    fixSmsCode: 'تعديل رمز التحقق',
    inputSmsCode: 'يرجى إدخال رمز التحقق (4 أرقام)',
    smsCodeError: 'يتكون رمز التحقق من 4 أرقام',
    vip_7_day: '7 أيام لكبار الشخصيات',
    vip_30_day: '30 يومًا لكبار الشخصيات',
    vipType: 'نوع كبار الشخصيات',
    vipLevel: 'مستوى كبار الشخصيات',
    vipEndTime: 'الموعد النهائي لكبار الشخصيات',
    vipCharge: 'إعادة شحن VIP',
    vipExp: 'تعديل قيمة تجربة VIP',
    vip_365_day: '365 يومًا لكبار الشخصيات',
    addVipExp: 'زيادة قيمة خبرة VIP',
    role: 'الأدوار',
    oldName: 'الاسم المستعار القديم',
    newName: 'لقب جديد',
    vipExpValue: 'قيمة خبرة كبار الشخصيات'
  },
  agents: {
    updatePassword: 'تغيير كلمة المرور',
    inputPassword: 'من فضلك أدخل كلمة مرورك',
    confirm: 'أكد',
    reset: 'إعادة تعيين',
    passwordLen: 'لا يمكن أن يكون طول كلمة المرور أقل من 3 أرقام',
    updatePasswordFailed: 'فشل تغيير كلمة المرور',
    createLink: 'رابط التسجيل',
    agentType: 'نوع الموزع',
    createAgentRegister: 'إنشاء رابط التسجيل',
    createLinkAndCopy: 'الرجاء عمل رابط التسجيل ، ثم نسخه ...',
    selectAgentType: 'يرجى اختيار نوع الموزع',
    copy: 'نسخة',
    distributor: 'موزعين',
    CreateLinkFailed: 'لاعب غير فشل رابط التسجيل',
    inputAgentAccount: 'الرجاء إدخال حساب الموزع',
    search: 'بحث',
    id: 'عدد',
    account: 'اسم المستخدم',
    password: 'كلمة المرور',
    registerTime: 'وقت التسجيل',
    lastTime: 'وقت تسجيل الدخول الحديثة',
    coinCount: 'عدد العملات الذهبية',
    diamondCount: 'عدد الماس',
    operate: 'التشغيل',
    recharge: 'إعادة شحن البطارية',
    chargeRecord: 'شراء سجل البطاقة',
    activate: 'تفعيل',
    unactive: 'تعطيل',
    modifyPassword: 'تغيير كلمة المرور',
    chargeType: 'نوع',
    selectChargeType: 'يرجى اختيار نوع',
    count: 'كمية',
    chargedId: 'إعادة شحن معرف الحساب',
    orderId: 'رقم الطلب',
    operateType: 'نوع العملية',
    chargeBefore: 'قبل إعادة الشحن',
    chargeAfter: 'بعد إعادة الشحن',
    chargeTime: 'إعادة شحن الوقت',
    cancel: 'ألغيت',
    inputChargeCount: 'الرجاء إدخال عدد عمليات إعادة الشحن',
    inputCountIn100000: 'الرجاء إدخال ما بين 100000 و 100000',
    idNotEmpty: 'لا يمكن أن يكون المعرف فارغًا',
    accountNotEmpty: 'اسم المستخدم لا يمكن أن يكون فارغا',
    passwordNotEmpty: 'لا يمكن أن تكون كلمة المرور فارغة',
    playerNotExist: 'لاعب غير موجود',
    selectOne: 'يرجى اختيار المستخدم أولاً',
    admin: 'سوبر المسؤول',
    service: 'خدمة العملاء',
    agent_1: 'الموزع الرئيسي',
    agent_2: 'الموزع الثانوي',
    promoter: 'متعهد',
    player: 'لاعبين',
    chargeCard: 'سجل بطاقة الشحن',
    sellCard: 'سجل المبيعات',
    outId: 'بيع حساب',
    inId: 'شراء حساب',
    buyDiamond: 'شراء الماس',
    buyCoin: 'شراء العملات الذهبية',
    giftDiamond: 'هدية الماس',
    giftCoin: 'هدية عملة ذهبية',
    chargeCoin: 'عملة ذهبية',
    chargeDiamond: 'مليئة بالماس',
    playerId: 'معرف اللاعب'
  },
  statManager: {
    totalUser: 'العدد الإجمالي للأشخاص',
    onlineRooms: 'عدد الغرف على الانترنت',
    newUsers: 'عدد الأشخاص المسجلين',
    loginUsers: 'عدد الاشخاص',
    avrgTime: 'متوسط مدة الانترنت',
    maxTime: 'المدة القصوى عبر الإنترنت',
    minTime: 'الحد الأدنى لمدة الإنترنت',
    userOrder: 'عدد دافعي',
    totalOrder: 'مجموع الطلبات',
    appleOrder: 'أوامر أبل',
    googleOrder: 'رقم طلب Google',
    diamondOrder: 'أوامر الماس',
    coinOrder: 'أوامر العملة الذهبية',
    totalOrderCnt: 'رقم الطلب',
    hanks: 'إجمالي عدد المكاتب',
    totalPlayers: 'عدد المشاركين',
    clubRoom: 'عدد غرف النادي',
    albCoins: 'لعبة واحدة',
    albUpGrade: 'لعبة اثنين',
    albPDK: 'لعبة ثلاثة',
    room4Person: '4 شخص الغرفة',
    room5Person: '5 شخص الغرفة',
    room6Person: '6 شخص الغرفة',
    room7Person: '7 شخص الغرفة',
    room8Person: '8 شخص الغرفة',
    albCoinsUids: 'عدد المشاركين في اللعبة الأولى',
    albUpGradeUids: 'عدد المشاركين في اللعبة الثانية',
    albPDKUids: 'عدد المشاركين في اللعبة الثالثة',
    optType1: 'استهلاك المباراة',
    optType2: 'الطلب ل',
    optType3: 'هدية صديق',
    optType4: 'تفاحة',
    optType5: 'جوجل',
    optType6: 'الويب',
    optType7: 'وكيل شحن',
    optType8: 'إعادة شحن الخلفية',
    optType9: 'تم الحصول على رقم جديد',
    optType10: 'صرف العملات الذهبية',
    optType12: 'الحصول على ردود الفعل',
    optType13: 'الحصول في الخلفية',
    optType100: 'افتراضي',
    date: 'تاريخ',
    type: 'نوع البند',
    optType: 'نوع السجل',
    startTime: 'وقت البدء',
    endTime: 'وقت النهاية',
    search: 'تحقيق',
    to: 'إلى',
    serial3days: '3 أيام حية',
    serial7days: '7 أيام في اليوم',
    optType14: 'النشاط',
    optType15: 'هدية النظام',
    optType16: 'المتصدرين',
    optType17: 'التخلي عن العملات الذهبية',
    optType18: 'تحويل سند',
    optType19: 'شراء الدعائم',
    optType20: 'كسب السلم',
    optType21: 'استهلاك السلم',
    optType22: 'مكافأة قوة المعركة',
    provider: 'قناة',
    originalId: 'معرّف طلب اللعبة',
    state: 'حالة الطلب',
    firstChargeTip: 'فوق حتى',
    firstCharge: 'أول',
    orderId: 'معرّف طلب القناة',
    goodsId: 'معرف المنتج',
    amount: 'المبلغ ($)',
    count: 'كمية',
    sar: 'المبلغ العربي',
    createTime: 'وقت البدء',
    updateTime: 'تحديث الوقت',
    stateInit: 'بدء الأمر',
    stateSuccess: 'نجاح',
    stateFail: 'بالفشل',
    stateCannel: 'إلغاء',
    unfirstCharge: 'هذه ليست المرة الأولى',
    inputGoodsId: 'يرجى إدخال معرف المنتج',
    inputOriginalId: 'يرجى إدخال معرف طلب اللعبة',
    inputOrderId: 'يرجى إدخال معرف طلب القناة',
    optType23: 'هدية كبار الشخصيات',
    optType24: 'هدية ملزمة',
    optType25: 'استهلاك لعبة صغيرة',
    optType26: 'جائزة اللعبة الصغيرة',
    optType27: 'جائزة سلم سلم',
    optType28: 'عدد مشتريات الكنية',
    optType29: 'استهلاك صندوق الكنز',
    optType30: 'مكافأة الكنز في الصدر',
    optType31: 'استهلاك المسابقة',
    optType32: 'جوائز المسابقة',
    optType33: 'استهلاك الجاشا',
    optType34: 'مكافآت Gacha'
  },
  gameSetting: {
    add: 'إضافة',
    activity: 'محتوى الإعلان',
    time: 'وقت الخلق',
    updateTime: 'وقت التحديث',
    operate: 'التشغيل',
    edit: 'تحرير',
    delete: 'حذف',
    notice: 'إعلان',
    notEmpty: 'لا يمكن أن يكون الإعلان فارغًا',
    save: 'حفظ',
    addAndEdit: 'إضافة أو تحرير معلومات الإعلان',
    title: 'عنوان رئيسي',
    inputTitle: 'الرجاء إدخال العنوان',
    version: 'رقم الإصدار',
    inputVersion: 'الرجاء إدخال رقم الإصدار',
    type: 'نوع',
    selectType: 'يرجى اختيار نوع',
    text: 'نص',
    image: 'صور',
    imageUrl: 'رابط الصورة',
    inputImageUrl: 'الرجاء إدخال رابط الصورة',
    addMail: 'إرسال البريد',
    mailContent: 'محتوى الرسالة',
    inputMailContent: 'الرجاء إدخال محتوى الرسالة',
    allUser: 'لاعب كامل',
    users: 'بعض اللاعبين',
    uids: 'هويات اللاعب',
    inputMailUids: 'الرجاء إدخال معرف اللاعب مفصولة بفاصلة',
    noGiveItem: 'لا العملات الذهبية أو الماس',
    itemType: 'نوع البند',
    itemCount: 'عدد العناصر',
    inputItemCount: 'الرجاء إدخال عدد العناصر',
    selectItemType: 'يرجى اختيار نوع العنصر',
    jumpUrl: 'عنوان القفز',
    inputJumpUrl: 'الرجاء إدخال عنوان القفز (اختياري)',
    period: 'حدود الإعلانات',
    inputPeriod: 'الرجاء إدخال إعلان',
    times: 'ظهور فرصة',
    once: 'مرة واحدة فقط',
    day: 'مرة واحدة في اليوم',
    always: 'فوق',
    selectTimesType: 'يرجى تحديد التوقيت',
    place: 'تظهر في',
    selectPlaceType: 'يرجى اختيار الموقع',
    login: 'واجهة تسجيل الدخول',
    hall: 'واجهة اللوبي',
    ios_version: 'رقم إصدار Apple',
    inputIOSVersion: 'يرجى إدخال رقم إصدار Apple',
    inputAndroidVersion: 'يرجى إدخال رقم إصدار Android',
    android_version: 'رقم إصدار Android',
    addAndEditVerion: 'إضافة أو تحرير نسخة',
    fb_type: 'نوع الأنطباع',
    fb_report: 'نقل',
    fb_feedback: 'خدمة العملاء',
    fb_content: 'المحتوى',
    fb_reportTypes: 'نوع السؤال',
    fb_mail: 'بريد',
    fb_images: 'صورة',
    fb_reportUid: 'معرف اللاعب المبلغ عنه',
    fb_state: 'الحالة',
    fb_createTime: 'وقت الابتكار',
    fb_updateTime: 'وقت المعالجة',
    fb_init: 'غير المجهزة',
    fb_handle: 'التعامل مع',
    fb_reject: 'رفض',
    fb_reportType1: 'مشاكل اللعبة',
    fb_reportType2: 'اقتراحات اللعبة',
    fb_reportType3: 'قضايا الدفع',
    fb_reportType4: 'نداء الحظر',
    fb_reportType5: 'آخر',
    fb_reportType6: 'Malvertising',
    fb_reportType7: 'إساءة',
    fb_reportType8: 'فاحش',
    fb_reportType9: 'آخر',
    inputReportUid: 'الرجاء إدخال معرف اللاعب المبلغ عنه',
    fb_showDetail: 'اظهر التفاصيل'
  },
  operation: {
    addAndEdit: 'إضافة أو تعديل العناصر',
    expression: 'التعبير',
    gift: 'انتقد هدية',
    props: 'الدعائم مول',
    productType: 'نوع المنتج',
    selectProductType: 'يرجى اختيار نوع المنتج',
    productName: 'اسم المنتج',
    inpurtProductName: 'الرجاء إدخال اسم المنتج',
    codeName: 'اسم البرنامج',
    inpurtCodeName: 'الرجاء إدخال اسم البرنامج',
    costType: 'نوع الاستهلاك',
    selectCostType: 'يرجى اختيار نوع الاستهلاك',
    productPrice: 'سعر السلع',
    inputProductPrice: 'الرجاء إدخال سعر المنتج',
    charmValue: 'جاذبية',
    inputCharmValue: 'الرجاء إدخال قيمة السحر',
    productDesc: 'وصف المنتج',
    inputProductDesc: 'الرجاء إدخال وصف',
    timeType: 'نوع الوقت',
    deadline: 'حد اقصى',
    period: 'فترة',
    selectDatetime: 'فترة الاختيار',
    gameProps: 'الدعائم اللعبة',
    productStatus: 'حالة السلع',
    selectProductStatus: 'يرجى تحديد حالة المنتج',
    onShelf: 'ضع على الرف',
    offShelf: 'من الرف',
    tentative: 'مؤقت',
    inputRefuseContent: 'الرجاء إدخال المحتوى المرفوض',
    vipType: 'نوع VIP',
    selectVipType: 'الرجاء تحديد نوع VIP',
    all: 'الكل',
    vip: 'VIP فقط',
    notvip: 'غير VIP فقط',
    periodType: 'نوع الشيخوخة',
    selectPeriodType: 'الرجاء تحديد نوع الشيخوخة',
    forever: 'دائم',
    limit: 'فعالة لفترة محدودة',
    days: 'الجنة',
    selectDays: 'حدد الأيام',
    day7: '7 أيام',
    day30: '30 يوما'
  },
  roles: {
    account: 'إدارة حساب المستخدم',
    accountNotEmpty: 'لا يمكن أن يكون مدير الحساب فارغًا',
    depart: 'قسم',
    selectDeparts: 'الرجاء تحديد القسم',
    technology: 'تقنية',
    product: 'المنتج',
    operation: 'عملية',
    design: 'التصميم',
    manager: 'إدارة',
    selectRoles: 'الرجاء تحديد أذونات الدور',
    rolePermission: 'أذونات الدور',
    super: 'مشرف متميز',
    admin: 'مدير',
    optId: 'معرف المشغل',
    optUserId: 'معرف كائن العملية',
    optType: 'نوع العملية',
    changeDiamond: 'اشحن الماس',
    changeCoin: 'اشحن العملات الذهبية',
    fixNickname: 'غير اسم المستخدم',
    fixModifyName: 'الأوقات التي تم تعديلها وإعادة تسميتها',
    fixSmsCode: 'رمز التحقق من الهاتف',
    forbidChat: 'كتم الصوت',
    forbidGame: 'ممنوع السفر',
    chargeVip: 'إعادة شحن VIP',
    addVipExp: 'تعديل قيمة تجربة VIP',
    saveNotice: 'أعلن',
    updateNotice: 'إعلان التحديث',
    deleteNotice: 'حذف عام',
    sendMail: 'ارسل بريد الكتروني',
    saveVersion: 'احفظ رقم الإصدار',
    updateVersion: 'تحديث رقم الإصدار',
    deleteVersion: 'احذف رقم الإصدار',
    versionId: 'معرف الإصدار',
    mailId: 'رقم البريد',
    noticeId: 'معرف الإعلان',
    day: 'يوم',
    optValue: 'عمل',
    optAccount: 'حساب المشغل',
    adminNotFind: 'لم يتم العثور على حساب مسؤول',
    optLog: 'سجل العمليات',
    deleteUser: 'ستؤدي هذه العملية إلى حذف الحساب نهائيًا ، هل تريد المتابعة؟'
  },
  default: {
    money: 'نقود',
    coin: 'العملات الذهبية',
    diamond: 'الماس',
    save: 'حفظ',
    add: 'إضافة',
    createTime: 'وقت الخلق',
    updateTime: 'وقت التحديث',
    operate: 'التشغيل',
    edit: 'تحرير',
    delete: 'حذف',
    countLimit: 'حد الكمية',
    success: 'نجاح',
    operateSuccess: 'عملية ناجحة',
    operateFailed: 'فشلت العملية',
    sum: 'مجموع',
    pass: 'بواسطة',
    reject: 'رفض',
    unhandler: 'غير المجهزة',
    handler: 'التعامل مع',
    invalidData: 'بيانات غير صالحة',
    addNew: 'أضف',
    cancel: 'إلغاء',
    confirm: 'تحديد',
    optlog: 'سجل العمليات',
    cancelDelete: 'غير محذوف',
    tip: 'مستعجل'
  }
};