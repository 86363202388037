import Vue from 'vue';
import Router from 'vue-router';
/* Layout */

import Layout from '@/layout/index.vue';
/* Router modules */

import agentsRouter from "./modules/agents";
import gameSettingRouter from "./modules/gameSetting";
import operationRouter from "./modules/operation";
import accountRouter from "./modules/account";
Vue.use(Router);
/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/

export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import(
    /* webpackChunkName: "login" */
    '@/views/login/index.vue');
  },
  meta: {
    hidden: true
  }
}, {
  path: '/404',
  component: function component() {
    return import(
    /* webpackChunkName: "404" */
    '@/views/error-page/404.vue');
  },
  meta: {
    hidden: true
  }
}, {
  path: '/401',
  component: function component() {
    return import(
    /* webpackChunkName: "401" */
    '@/views/error-page/401.vue');
  },
  meta: {
    hidden: true
  }
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import(
      /* webpackChunkName: "dashboard" */
      '@/views/dashboard/index.vue');
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/

export var asyncRoutes = [{
  path: '/stat',
  component: Layout,
  meta: {
    title: 'statManager',
    icon: 'chart',
    roles: ['admin', '200', '300'],
    alwaysShow: true // will always show the root menu

  },
  children: [{
    path: 'dailylife',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/dailylife.vue');
    },
    name: 'dailyLife',
    meta: {
      title: 'dailyLife',
      roles: ['admin', '300', '200'] // or you can only set roles in sub nav

    }
  }, {
    path: 'chargeStat',
    component: function component() {
      return import(
      /* webpackChunkName: "chargeStat" */
      '@/views/statManager/chargeStat.vue');
    },
    name: 'chargeStat',
    meta: {
      title: 'chargeStat',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'roomStat',
    component: function component() {
      return import(
      /* webpackChunkName: "roomStat" */
      '@/views/statManager/roomStatNew.vue');
    },
    name: 'roomStat',
    meta: {
      title: 'roomStat',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'itemStat',
    component: function component() {
      return import(
      /* webpackChunkName: "itemStat" */
      '@/views/statManager/itemStat.vue');
    },
    name: 'itemStat',
    meta: {
      title: 'itemStat',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'queryItem',
    component: function component() {
      return import(
      /* webpackChunkName: "queryItem" */
      '@/views/statManager/queryItem.vue');
    },
    name: 'queryItem',
    meta: {
      title: 'queryItem',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'giveGiftList',
    component: function component() {
      return import(
      /* webpackChunkName: "queryItem" */
      '@/views/statManager/giveGiftList.vue');
    },
    name: 'giveGiftList',
    meta: {
      title: 'giveGiftList',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'AF',
    component: function component() {
      return import(
      /* webpackChunkName: "queryItem" */
      '@/views/statManager/af.vue');
    },
    name: 'AF',
    meta: {
      title: 'AF',
      roles: ['admin', '300', '200'] // or you can only set roles in sub nav

    }
  }, {
    path: 'AFuser',
    component: function component() {
      return import(
      /* webpackChunkName: "queryItem" */
      '@/views/statManager/afUser.vue');
    },
    name: 'AFuser',
    meta: {
      title: 'AFuser',
      roles: ['admin', '300', '200'] // or you can only set roles in sub nav

    }
  }]
}, {
  path: '/log',
  component: Layout,
  meta: {
    title: 'logStat',
    icon: 'chart',
    roles: ['admin', '300'],
    alwaysShow: true // will always show the root menu

  },
  children: [{
    path: 'dailyReport',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/dailyReport.vue');
    },
    name: 'dailyReport',
    meta: {
      title: 'dailyReport',
      roles: ['admin'] // or you can only set roles in sub nav

    }
  }, {
    path: 'activity',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/activityReport.vue');
    },
    name: 'activity',
    meta: {
      title: 'activity',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'fruitMachine',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/fruitMachine.vue');
    },
    name: 'fruitMachine',
    meta: {
      title: 'fruitMachine',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'laba',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/laba.vue');
    },
    name: 'laba',
    meta: {
      title: 'laba',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'niuniu',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/niuniu.vue');
    },
    name: 'niuniu',
    meta: {
      title: 'niuniu',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'turn',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/turn.vue');
    },
    name: 'turn',
    meta: {
      title: 'turn',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'voiceRoom',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/voiceRoom.vue');
    },
    name: 'voiceRoom',
    meta: {
      title: 'voiceRoom',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'bag',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/bagReport.vue');
    },
    name: 'bag',
    meta: {
      title: 'bag',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'homePage',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/homePage.vue');
    },
    name: 'homePage',
    meta: {
      title: 'homePage',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'userReport',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/userReport.vue');
    },
    name: 'userReport',
    meta: {
      title: 'userReport',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'taskStat',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/taskStat.vue');
    },
    name: 'taskStat',
    meta: {
      title: 'taskStat',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'itemConsume',
    component: function component() {
      return import(
      /* webpackChunkName: "dailylife" */
      '@/views/statManager/itemConsume.vue');
    },
    name: 'itemConsume',
    meta: {
      title: 'itemConsume',
      roles: ['admin', '300'] // or you can only set roles in sub nav

    }
  }]
}, {
  path: '/user',
  component: Layout,
  meta: {
    title: 'user',
    icon: 'user',
    roles: ['admin', '500', '200', '300'],
    alwaysShow: true // will always show the root menu

  },
  children: [{
    path: 'query',
    component: function component() {
      return import(
      /* webpackChunkName: "userQuery" */
      '@/views/user/query.vue');
    },
    name: 'userQuery',
    meta: {
      title: 'userQuery',
      roles: ['admin', '500', '200', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'cardRecord',
    component: function component() {
      return import(
      /* webpackChunkName: "cardRecord" */
      '@/views/user/cardRecord.vue');
    },
    name: 'cardRecord',
    meta: {
      title: 'cardRecord',
      roles: ['admin', '500', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'propRecord',
    component: function component() {
      return import(
      /* webpackChunkName: "cardRecord" */
      '@/views/user/propRecord.vue');
    },
    name: 'propRecord',
    meta: {
      title: 'propRecord',
      roles: ['admin', '500', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'vipUserQuery',
    component: function component() {
      return import(
      /* webpackChunkName: "cardRecord" */
      '@/views/user/vipUser.vue');
    },
    name: 'vipUserQuery',
    meta: {
      title: 'vipUserQuery',
      roles: ['admin', '500', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'nameRecord',
    component: function component() {
      return import(
      /* webpackChunkName: "cardRecord" */
      '@/views/user/nameRecord.vue');
    },
    name: 'nameRecord',
    meta: {
      title: 'nameRecord',
      roles: ['admin', '500', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'coinAbnormal',
    component: function component() {
      return import(
      /* webpackChunkName: "cardRecord" */
      '@/views/user/coinAbnormal.vue');
    },
    name: 'coinAbnormal',
    meta: {
      title: 'coinAbnormal',
      roles: ['admin', '500', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'rankChange',
    component: function component() {
      return import(
      /* webpackChunkName: "cardRecord" */
      '@/views/user/rankChange.vue');
    },
    name: 'rankChange',
    meta: {
      title: 'rankChange',
      roles: ['admin', '500', '300'] // or you can only set roles in sub nav

    }
  }, {
    path: 'sendGiftChange',
    component: function component() {
      return import(
      /* webpackChunkName: "cardRecord" */
      '@/views/user/sendGiftChange.vue');
    },
    name: 'sendGiftChange',
    meta: {
      title: 'sendGiftChange',
      roles: ['admin', '500', '300'] // or you can only set roles in sub nav

    }
  }]
}, agentsRouter, gameSettingRouter, operationRouter, accountRouter];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: function scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;