exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.sidebar-container .horizontal-collapse-transition {\n  -webkit-transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;\n  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;\n}\n.sidebar-container .scrollbar-wrapper {\n  overflow-x: hidden !important;\n}\n.sidebar-container .el-scrollbar__view {\n  height: 100%;\n}\n.sidebar-container .el-scrollbar__bar.is-vertical {\n  right: 0px;\n}\n.sidebar-container .el-scrollbar__bar.is-horizontal {\n  display: none;\n}", ""]);

// exports
exports.locals = {
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};