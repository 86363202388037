exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.el-submenu.is-active > .el-submenu__title {\n  color: #f4f4f5 !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title,\n.full-mode .el-submenu .el-menu-item {\n  min-width: 210px !important;\n  background-color: #1f2d3d !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title:hover,\n.full-mode .el-submenu .el-menu-item:hover {\n  background-color: #001528 !important;\n}\n.simple-mode.first-level .submenu-title-noDropdown {\n  padding: 0 !important;\n  position: relative;\n}\n.simple-mode.first-level .submenu-title-noDropdown .el-tooltip {\n  padding: 0 !important;\n}\n.simple-mode.first-level .el-submenu {\n  overflow: hidden;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title {\n  padding: 0px !important;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title .el-submenu__icon-arrow {\n  display: none;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title > span {\n  visibility: hidden;\n}", ""]);

// exports
exports.locals = {
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};