/* tslint:disable */
import "./404";
import "./back-top";
import "./bug";
import "./chart";
import "./clipboard";
import "./component";
import "./dashboard";
import "./documentation";
import "./drag";
import "./edit";
import "./education";
import "./email";
import "./example";
import "./excel";
import "./exit-fullscreen";
import "./eye-off";
import "./eye-on";
import "./form";
import "./fullscreen";
import "./guide-2";
import "./guide";
import "./hamburger";
import "./icon";
import "./international";
import "./language";
import "./like";
import "./link";
import "./list";
import "./lock";
import "./message";
import "./money";
import "./nested";
import "./password";
import "./pdf";
import "./people";
import "./peoples";
import "./qq";
import "./search";
import "./shopping";
import "./size";
import "./skill";
import "./star";
import "./tab";
import "./table";
import "./theme";
import "./tree-table";
import "./tree";
import "./user";
import "./wechat";
import "./zip";